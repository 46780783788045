:root {
  --primary-color: #fc56c1;
  --secondary-color: #fc3e81;
  --confirm-color: #51af54;
  --alert-color: #faa92d;
  --cancel-color: #eb4c42;
  --info-color: #fc3e81;
  --dark-color: #202020;
  --dark-medium-color: #707070;
  --dark-light-color: #aeaeae;
  --dark-light-color-transparent: rgba(174, 174, 174, 0.1);
  --bg-color: #f2f2f2;
  --empty-color: rgba(0, 0, 0, 0.05);
  --disabled-color: rgba(252, 62, 129, 0.1);
  --invalid-color: rgba(250, 169, 45, 0.2); }
